.DonatePage{
    background-color: whitesmoke;
}
.DonatePage .header{
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding-top: 10vh;
    height:30vh;
    margin-bottom: 10vh;
    background-image: url(../assets/Campus_Aerial_View_2.jpeg);
    background-size: 100%;
    background-blend-mode: multiply;
    background-color: whitesmoke;  
}

.DonatePage .header h1{
    font-weight: 50;
    color:whitesmoke;
    margin-top:0px;
    margin-bottom:0px;
    font-size: 100px;
    font-family:'Bold';
}

.DonatePage .body {
    display: flex;
    flex-direction: row;
    margin-bottom: 10vh;
    height: auto;
}

.GFMContainer h1{
    font-size: 40px;
    color: black;
    font-weight: 500;
    text-align: center;
    align-items: center;
    margin: 0;
}
.GFMContainer{
    padding-top: 0vh;
    
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    height:40vh;
    width: 45vw;
    margin-left: 5vw;
    /**background-image: url(../assets/Campus_Aerial_View_2_blurry.jpeg);**/
    background-color: whitesmoke;
    
}
.MAEFundContainer h1{
    color: black;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    align-items: center;
    margin: 0;
}
.MAEFundContainer{
    padding-top: 0vh;

    width:45vw;
    height:40vh;
    margin-right: 5vw;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    /**background-image: url(../assets/Campus_Aerial_View_2_blurry.jpeg);**/
    background-color: whitesmoke;

    
}


.HeaderContainer img{
    justify-content: center;
    width: 25vw;
}

.MAEFundContainer .buttonContainer{
    padding-top: 3vh;
    width: 50vw;
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 0vw;
}
.MAEFundContainer .buttonContainer img{
    height:7vh;
    
}
.GFMContainer .buttonContainer{
    padding-top: 3vh;
    width: 50vw;
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 0vw;
    
}
.GFMContainer .buttonContainer img{
    height:7vh;
}

.GFMContainer .button{
    border: 1px solid #e79671;
    border-radius: 5px;
    font-size: 1.5vw;
    font-family:'Regular';
    width: 15vw;
    height: 5vw;
    /* Additional styles */
    background-color: #121619;
    color: whitesmoke; 
}
.MAEFundContainer .button{
    border: 1px solid #e79671;
    border-radius: 5px;
    font-size: 1.5vw;
    font-family:'Regular';
    width: 15vw;
    height: 5vw;
    /* Additional styles */
    background-color: #121619;
    color: whitesmoke; 
}
@media only screen and (max-aspect-ratio:1 / 1){
    .DonatePage .header{
        display:flex;
        position: relative;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        padding-top: 7.5vh;
        height:22.5vh;
        margin-bottom: 5vh;
        background-image: url(../assets/Campus_Aerial_View_2.jpeg);
        background-size: cover;
        background-blend-mode: multiply;
        background-color: whitesmoke;  
    }
    .DonatePage .header h1{
        font-weight: 50;
        color:whitesmoke;
        margin-top:0px;
        margin-bottom:0px;
        font-size: 70px;
        font-family:'Bold';
    }
    .DonatePage .body {
        display: flex;
        flex-direction: column;
        margin-bottom: 5vh;
        height: auto;
    }
    .GFMContainer h1{
        font-size: 30px;
        font-family: 'Regular';
        color: black;
        font-weight: 300;
        text-align: center;
        align-items: center;
        margin: 0;
    }
    .GFMContainer{
        padding-top: 0vh;
        margin-left: 0px;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        height:30vh;
        width: 100vw;
        
        /**background-image: url(../assets/Campus_Aerial_View_2_blurry.jpeg);**/
        background-color: whitesmoke;
        
    }
    .MAEFundContainer h1{
        color: black;
        font-size: 30px;
        font-family: 'Regular';
        font-weight: 300;
        text-align: center;
        align-items: center;
        margin: 0;
    }
    .MAEFundContainer{
        padding-top: 0vh;
        margin-right: 0px;
    
        width:100vw;
        height:30vh;
        
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        /**background-image: url(../assets/Campus_Aerial_View_2_blurry.jpeg);**/
        background-color: whitesmoke;
    
        
    }
    .MAEFundContainer .buttonContainer{
        padding-top: 3vh;
        width: 50vw;
        height: auto;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-left: 0vw;
    }
    .MAEFundContainer .buttonContainer img{
        height:5vh;
        
    }
    .GFMContainer .buttonContainer{
        padding-top: 3vh;
        width: 50vw;
        height: auto;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-left: 0vw;
        
    }
    .GFMContainer .buttonContainer img{
        height:5vh;
    }
    
    .GFMContainer .button{
        border: 1px solid #e79671;
        border-radius: 5px;
        font-size: 1.5vw;
        font-family:'Regular';
        width: 45vw;
        height: 15vw;
        /* Additional styles */
        background-color: #121619;
        color: whitesmoke; 
    }
    .MAEFundContainer .button{
        border: 1px solid #e79671;
        border-radius: 5px;
        font-size: 1.5vw;
        font-family:'Regular';
        width: 45vw;
        height: 15vw;
        /* Additional styles */
        background-color: #121619;
        color: whitesmoke; 
    }
}

/*

@media only screen and (max-width: 600px) {
    .DonatePage .HeaderContainer{
        display:flex;
        position: relative;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        padding-top: 10vh;
        margin-bottom: 7.5vw;
        height:22vh;
        background-image: url(../assets/Campus_Aerial_View_2.jpeg);
        background-size: 100%;
        background-blend-mode: multiply;
        background-color: whitesmoke;  
    }
    
    .DonatePage .HeaderContainer h1{
        font-weight: 50;
        color:whitesmoke;
        font-size: 50px;
        font-family:'Bold';
    }
    
    .GFMContainer .button{
        border: 2px solid #e79671;
        border-radius: 100px;
        font-size: 3vw;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        width: 20vw;
        height: 6vw;
        background-color: #121619;
        
        color: whitesmoke; 
    }
    .MAEFundContainer .button{
        border: 2px solid #e79671;
        border-radius: 100px;
        font-size: 3vw;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        width: 20vw;
        height: 6vw;

        background-color: #121619;
        color: whitesmoke; 
    }
    .MAEFundContainer h1{
        color: #121619;
        font-weight: 500;
        font-size: 25px;
        text-align: center;
        align-items: center;
        margin: 0;
    }
    .GFMContainer h1{
        color: #121619;
        font-weight: 500;
        font-size: 25px;
        text-align: center;
        align-items: center;
        margin: 0;
    }
    .GFMContainer .buttonContainer img{
        width:20vw;
        padding-top: 3vh;
    }
    .MAEFundContainer .buttonContainer img{
        width:16vw;
        padding-left: 2vw;
        padding-top: 4vh;
        
    }
   
  }
  */