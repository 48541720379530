.projects {
    background-color: whitesmoke;  
    align-items: center;
    justify-content: center;
}

.projects .header {
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding-top: 10vh;
    margin-bottom: 10vh;
    height:30vh;
    background-color: #121619;
}

.projects .header h1 {
    font-weight: 50;
    color:whitesmoke;
    font-size: 70px;
    font-family:'Regular';
}
.projects h2{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 55px;
    line-height: 55px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5vh;
    
}
.projects h3{
    font-family:'Regular';
    font-size: 35px;
    line-height: 35px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 10vh;
    
}
.projects .projectsBody {
    align-items:center;
    justify-content: center;
    display:flex;
    flex-direction: row;
    height:auto;
    background-color: whitesmoke;
    margin-bottom: 15vh;
}
.projects .projectsBody .projects-body-left {

    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;
    text-align: center;

    height: 30vh;
    width: 20vw;

}
.projects .projectsBody .projects-body-middle {
    display: flex;
    flex-direction: column;

    align-items:center;
    justify-content: center;
    text-align: center;

    height: 30vh;
    width: 20vw;

    margin-left: 2.5vw;
    margin-right: 2.5vw;
}
.projects .projectsBody .projects-body-right {
    display: flex;
    flex-direction: column;

    align-items:center;
    justify-content: center;
    text-align: center;

    height: 30vh;
    width: 20vw;
    
}

.projects .projectsBody .pbl-img-container {
    
    
    background-color: whitesmoke;
    height:30vh;
    width: 20vw; 
}
.projects .projectsBody .pbm-img-container {
    
    background-color: whitesmoke;
    height:30vh;
    width:20vw;
    
}
.projects .projectsBody .pbr-img-container {
    background-color: #121619;
    height:30vh;
    width: 20vw;
    text-align: center;
}

.projects .projectsBody .projects-body-left .text {
    
    width: fit-content;
    position:relative;
    width: 18vw;
    text-align: left;
    
    background-color: #121619;
    color: whitesmoke;
    padding: 1vw;
    border: 2px solid whitesmoke;
    
    font-family: 'Regular';
    font-size: larger;
    
}
.projects .projectsBody .projects-body-middle .text {
    
    width: fit-content;
    position:relative;
    width: 18vw;
    text-align: left;
    
    background-color: #121619;
    color: whitesmoke;
    padding: 1vw;
    border: 2px solid whitesmoke;
    
    font-family: 'Regular';
    font-size: larger;
    
}
.projects .projectsBody .projects-body-right .text {
    
    
    width: fit-content;
    position:relative;
    text-align: left;
    width: 18vw;
    
    
    background-color: #121619;
    color: whitesmoke;
    padding: 1vw;
    border: 2px solid whitesmoke;
    
    font-family: 'Regular';
    font-size: larger;
    
}

.projects .pbl-img-container:hover{
    filter:brightness(125%);
    filter:saturate(125%);
}

.projects .pbm-img-container:hover{
    filter:brightness(125%);
    filter:saturate(125%);
}

.projects .pbr-img-container:hover{
    
    filter:brightness(125%);
    filter:saturate(125%);
}

.projects .projectsBody .pbl-img-container img{
    width: 20vw;
    height: 30vh;
}

.projects .projectsBody .pbm-img-container img{
    width: 20vw;
    height: 30vh;
}

.projects .projectsBody .pbr-img-container img{
    width: auto;
    height: 30vh;
}


@media only screen and (max-aspect-ratio:1 / 1){

    .projects .header {
        margin-bottom: 10vh;
        padding-top: 7.5vh;
        height:22.5vh;
        background-color: #121619;
    }
    
    .projects .header h1 {
        font-weight: 50;
        color:whitesmoke;
        font-size: 50px;
        font-family:'Regular';
    }
    .projects h2{
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 5vh;
        
    }
    .projects h3{
        font-size: 25px;
        line-height: 25px;

        margin-bottom: 10vh;
        margin-left: 6vw;
        margin-right: 6vw;
        
    }
    .projects .projectsBody {
        flex-direction: column;
        margin-bottom: 15vh;
    }
    .projects .projectsBody .projects-body-middle {
    
        height: 30vh;
        width: 60vw;
    
        margin-left: 0vw;
        margin-right: 0vw;
        margin-top: 6vw;
        margin-bottom: 6vw;
    }

    .projects .projectsBody .projects-body-right {
        height: 30vh;
        width: 60vw;
    }
    .projects .projectsBody .projects-body-left {    
        height: 30vh;
        width: 60vw;
    }

    .projects .projectsBody .pbl-img-container {
        height:30vh;
        width: 60vw; 
    }

    .projects .projectsBody .pbm-img-container { 
        height:30vh;
        width:60vw; 
    }

    .projects .projectsBody .pbr-img-container {
        background-color: #121619;
        height:30vh;
        width: 60vw;
    }
    .projects .projectsBody .pbl-img-container img{
        width: 60vw;
        height: 30vh;
    }
    
    .projects .projectsBody .pbm-img-container img{
        width: 60vw;
        height: 30vh;
    }
    
    .projects .projectsBody .pbr-img-container img{
        width: auto;
        height: 30vh;
    }

    .projects .projectsBody .projects-body-left .text {
        width: 56vw;
        padding: 2vw;
    }
    .projects .projectsBody .projects-body-middle .text {
        width: 56vw;
        padding: 2vw;
    }
    .projects .projectsBody .projects-body-right .text {
        width: 56vw;
        padding: 2vw;
    }
}

@media only screen and (max-aspect-ratio:4 / 3){

    .projects .header {
        margin-bottom: 10vh;
        padding-top: 7.5vh;
        height:22.5vh;
        background-color: #121619;
    }
    
    .projects .header h1 {
        font-weight: 50;
        color:whitesmoke;
        font-size: 50px;
        font-family:'Regular';
    }
    .projects h2{
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 5vh;
        
    }
    .projects h3{
        font-size: 25px;
        line-height: 25px;

        margin-bottom: 10vh;
        margin-left: 6vw;
        margin-right: 6vw;
        
    }
    .projects .projectsBody {
        flex-direction: column;
        margin-bottom: 15vh;
    }
    .projects .projectsBody .projects-body-middle {
    
        height: 30vh;
        width: 60vw;
    
        margin-left: 0vw;
        margin-right: 0vw;
        margin-top: 6vw;
        margin-bottom: 6vw;
    }

    .projects .projectsBody .projects-body-right {
        height: 30vh;
        width: 60vw;
    }
    .projects .projectsBody .projects-body-left {    
        height: 30vh;
        width: 60vw;
    }

    .projects .projectsBody .pbl-img-container {
        height:30vh;
        width: 60vw; 
    }

    .projects .projectsBody .pbm-img-container { 
        height:30vh;
        width:60vw; 
    }

    .projects .projectsBody .pbr-img-container {
        background-color: #121619;
        height:30vh;
        width: 60vw;
    }
    .projects .projectsBody .pbl-img-container img{
        width: 60vw;
        height: 30vh;
    }
    
    .projects .projectsBody .pbm-img-container img{
        width: 60vw;
        height: 30vh;
    }
    
    .projects .projectsBody .pbr-img-container img{
        width: auto;
        height: 30vh;
    }

    .projects .projectsBody .projects-body-left .text {
        width: 56vw;
        padding: 2vw;
    }
    .projects .projectsBody .projects-body-middle .text {
        width: 56vw;
        padding: 2vw;
    }
    .projects .projectsBody .projects-body-right .text {
        width: 56vw;
        padding: 2vw;
    }
}