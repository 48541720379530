.main-slide{
    padding-top: 0vh;
    width: 60vw;
    padding-left: 5vw;
}
.carousel .slider-wrapper{
    width: 100%;
}
.carousel img{
    pointer-events: all;
}

.carousel .thumb{
    width: 5%;
    height: 3vh;
}

.carousel{
    text-align: center;
    
}