.footer {
    width: 96vw;
    height: 30vh;
    background-color: #121619;
    display: flex;
    justify-content: space-between;
    align-items: top;
    padding-top: 5vh;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 5vh;
}

.footer .information{
    width: 66vw;
    display: flex;
    flex-direction: row;
}

.footer a {
    text-decoration: none; /* Remove the underline */
    color: inherit; /* Inherit the color from the parent element */
}
  
.footer h1{
    margin-left: 5.5vw;
    color:whitesmoke;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
}

.footer h3{
    margin-left: 5.5vw;
    color:whitesmoke;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight:500;
    text-align: left;
}

.footer p {
    color:whitesmoke;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight:500;
    text-align: center;    
}

.left, .middle, .right{
    flex: 1;
    text-align: left;
    flex-direction: column;
    color: whitesmoke;
}

.footer .middle {
    display: flex;
    text-align: left;
    flex-direction: column;
}

.footer .left .socialMedia{
    margin-left: 5vw;
    display: flex;    
    align-items: left;
    flex-direction: column;
}
.footer .left {
        
    width: 32vw;
    display: flex;
    text-align: left;
    flex-direction: column;

}

.footer .right{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}


.footer .right img{
    width: 10vw;
}

.socialMedia .instagram img {
    width:8vw;
    padding-top: 0vh;
}

@media only screen and (max-aspect-ratio:1 / 1){
    .footer{
        flex-direction: column;
        width: 100vw;
        padding-left: 0vw;
        padding-right: 0vw;
        height: auto;
        padding-top: 2.5vh;
        
    }
    .footer .information{
        width: auto;
        display: flex;
        flex-direction: row;
        margin-bottom: 10vh;
    }
    .footer .left .socialMedia{
        margin-left: 0vw;
        display: flex;
        align-items: left;
        flex-direction: column;
    
    }
    .footer p {
        color: whitesmoke;
        font-size: 17px;
        margin-left: 0vw;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        
    }
    .footer h1{
        margin-left: 6vw;
        color:whitesmoke;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 500;
        font-size: 25px;
    }
    .footer h3{
        margin-left: 6vw;
        color:whitesmoke;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight:500;
        font-size: 17px;
        text-align: left;
    }
    .socialMedia .instagram img {
        width:135px;
        padding-top: 0vh;
        margin-left: 4.75vw;
    }
    .footer .middle {
        
        width: 50vw;
        display: flex;
        text-align: left;
        flex-direction: column;
        
    
    }
    .footer .left {
        
        width: 50vw;
        display: flex;
        text-align: left;
        flex-direction: column;
    
    }
    .footer .right img{
        margin-left: 0vw;
        width: 25vw;
    }
    .footer .right{
        width: 100vw;
        height: auto;
        align-items: center;
        justify-content: left;
        text-align: left;
        display: flex;
        flex-direction: column;
       
    }
    
}

