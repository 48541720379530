.navbar {
  width: 88%;
  height: 10vh;
  background-color :#121619;
  display: flex;
  position: fixed;
  
  padding-left: 6vw;
  padding-right: 6vw;
  z-index: 1000;
  border-bottom: 2px solid whitesmoke;
}

/*this is the side with the logo*/
.navbar .leftSide {
  height: 100%;
  width: auto;
  display: flex;
  
  
}

/*this is the side with the tabs*/
.navbar .rightSide {  
  width: 80vw;
  height: auto;
  display: flex;
  

  justify-content: right;
}

.navbar Link{
  background: none;
  border: none;
  text-decoration: none;
  color:whitesmoke;
  font-family: 'Regular';
  padding:0;
}

/*this is the button for the projects dropdown*/
.navbar button{
  background-color: transparent;
  border: transparent;
  width: auto;
  height: 20px;
  text-align: right;
  height: auto;
  padding: 0px;
  margin: 0px;
}

.navbar h1 {
  color: whitesmoke;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
  

  
.navbar .leftSide img {
  width: 144px;
  height: 100px;
}
  

.navbar .rightSide .tabWrapper{
  width: 153.6px;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
}

.navbar .rightSide #close.projectsTabWrapper{
  width: 153.6px;
  display: flex;
}

.navbar .rightSide #open.projectsTabWrapper{
  display: flex;
  flex-direction: column;
  height: fit-content;
  
  width: 153.6px;
  
  margin-left: 0vw;
  background-color: #121619;
  border-bottom-right-radius: 1.25vh;
  border-bottom-left-radius: 1.25vh;
  
}

.navbar .rightSide #closed.projectsTabWrapper .tabWrapper{
  width: 153.6px;
  height: 10vh;
  
  
}
.navbar .rightSide #open.projectsTabWrapper .tabWrapper{
  width: 153.6px;
  height: 10vh;
  
  
  

  
}



.navbar .rightSide a {
  color: whitesmoke;
  width: auto;
  text-decoration: none;
  margin-top: 0px;
  margin-bottom: 0px;
  
  margin-left: 0px;
  font-family:'Regular';
  font-weight:540;
  font-size: 20px;
  line-height: 21px;
}


.navbar .rightSide .menuButton {
  background-color: transparent;
  border: none;
  
  color: whitesmoke;
  cursor: pointer;
}
  
.navbar .rightSide .menuButton svg {
  font-size: 20px;
  color: whitesmoke;
  margin-right: 6vw;
}
.navbar .rightSide svg {
  font-size: 14px;
  margin-left: 4px;
  margin-bottom: 0px;
  text-align: center;
  color: whitesmoke;
}
.navbar .leftSide .projectsHiddenLinks{
  display: flex;
  height: auto;
  align-items: left;
  justify-content: left;
  text-align: left;
  width: auto;
  flex-direction: column;
  margin-left: 0vw;
  background-color: #121619;
  border-bottom-right-radius: 1.25vh;
  border-bottom-left-radius: 1.25vh;
}

.navbar .rightSide .projectsHiddenLinks{
  display: flex;
  height: auto;
  align-items: left;
  justify-content: left;
  text-align: left;
  width: auto;
  flex-direction: column;
  margin-left: 0vw;
  background-color: #121619;
  border-left: 2px solid whitesmoke;
  border-right: 2px solid whitesmoke;
  border-bottom-right-radius: 1.25vh;
  border-bottom-left-radius: 1.25vh;
}
.navbar #close.projectsTabWrapper .projectsHiddenLinks{
  display: none;
  
}
.navbar .rightSide #open.projectsTabWrapper .projectsHiddenLinks a{
    color: whitesmoke;
    text-decoration: none;
    
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid whitesmoke;
    border-bottom-right-radius: 1.25vh;
    border-bottom-left-radius: 1.25vh;
    
    padding-left: 0.75vw;

    text-align: left;

    font-size: 17px;
    font-family:'Regular';
    font-weight:540;
    line-height: 17px;
}  
  

.navbar #open img {
  display: inherit;
}
  
.navbar #close img {
  display: inherit;
}
  

  
.navbar #close .hiddenLinks {
  display: none;
}




@media only screen and (min-aspect-ratio:1 / 1){
  .navbar #open .hiddenLinks {
    display: none;
  }
}
  
@media only screen and (max-aspect-ratio:1 / 1) {
  .navbar {
    width: 100%;
    height: 7.5vh;
    padding-left: 0vw;
    padding-right: 0vw;
    background-color :#121619;
    display: flex;
    position: fixed;
    flex-direction: row;
    z-index: 1000;
  }
  
  .navbar .rightSide .tabWrapper{
    width: 30px;
    text-align: right;
  }
  .navbar .rightSide .projectsTabWrapper{
    width: 30px;
    text-align: right;
  }
  .navbar .leftSide {
    height: 7.5vh;
    padding-left: 6vw;
    
    width: 26vw;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: left;
  }
  
  .navbar .leftSide {
    height: 7.5vh;
    padding-left: 0vw;
    width: 32vw;
    display: flex;
    
  }
  .navbar .rightSide{
    width: 68vw;
  }
  
  .navbar .leftSide img {
    padding-top: 0vh;
    margin-left: 6vw;
    width: 104px;
    height: 7.5vh;
  }
  
  .navbar .rightSide a {
    display: none;
  }
  .navbar .rightSide .projectsTabWrapper svg {
    display: none;
  }
  .navbar .leftSide .projectsTabWrapper svg {
    font-size: 14px;
    margin-left: 4px;
    margin-bottom: 0px;
    text-align: center;
    color: whitesmoke;
  }
  .navbar #open .rightside .menuButton svg{
    display: inherit;
  }
  
  .navbar #open .rightSide {
    justify-content: flex-end;
    width: auto;
    display: none;
    
  }
  .navbar .hiddenLinks {
    
    display: flex;
    height: auto;
    align-items: left;
    width: 38vw;
    flex-direction: column;
    margin-left: 0vw;
    background-color: #121619;
    
    border-right: 2px solid whitesmoke;
    border-bottom-right-radius: 1.25vh;
    
  }
  .navbar .leftSide .hiddenLinks {
    
    display: flex;
    height: auto;
    align-items: left;
    width: 38vw;
    flex-direction: column;
    margin-left: 0vw;  
    background-color: #121619;
    
    border-right: 2px solid whitesmoke;
    border-bottom: 2px solid whitesmoke;
    border-bottom-right-radius: 1.25vh;
    
  }
  .navbar .leftSide .hiddenLinks .projectsTabWrapper .projectsHiddenLinks {
    
    
    background-color: #121619;
    border-right: none;
    border-bottom: 2px solid whitesmoke;
    border-bottom-right-radius: 1.25vh;
    border-bottom-left-radius:0px;
    
  }
  
  
 
  
  .navbar .leftSide .hiddenLinks a{
    
    color: whitesmoke;
    text-decoration: none;
    
    margin-top: 10px;
    padding-bottom: 10px;
    padding-left: 6vw;
    
    margin-right: 0vw;

    text-align: left;

    font-size: 17px;
    font-family:'Regular';
    font-weight:540;
    line-height: 20px;
    
    border-bottom-right-radius: 1.5vh;
  }
  .navbar .rightSide .hiddenLinks a{
    
    color: whitesmoke;
    text-decoration: none;
    
    margin-top: 10px;
    padding-bottom: 10px;
    padding-left: 6vw;
    
    margin-right: 0vw;

    text-align: left;

    font-size: 17px;
    font-family:'Regular';
    font-weight:540;
    line-height: 20px;
    border-bottom: 2px solid whitesmoke;
    border-bottom-right-radius: 1.5vh;
  }
  .navbar .leftSide .hiddenLinks .projectsTabWrapper .projectsHiddenLinks a{
    
    color: whitesmoke;
    text-decoration: none;
    
    margin-top: 10px;
    padding-bottom: 10px;
    padding-left: 6vw;
    
    margin-right: 0vw;

    text-align: left;

    font-size: 17px;
    font-family:'Regular';
    font-weight:540;
    line-height: 20px;
    border-bottom: 2px solid #121619;
   
    border-bottom-right-radius: 1.5vh;
  }
  
  

}
  
  @media only screen and (min-aspect-ratio: 1 / 1) {
    .navbar .rightSide .menuButton {
      display: none;
    }
  
    .hiddenLinks {
      display: none;
    }

  }