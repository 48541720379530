.SDTeam {
    background-color: whitesmoke;  
    display: flex;
    flex-direction: column;
    height: auto;
    width:100vw;
}

.SDTeam .header {
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding-top: 10vh;
    margin-bottom: 20vh;
    height:30vh;
    background-color: #121619;
}

.SDTeam .header h1 {
    font-weight: 50;
    color:whitesmoke;
    font-size: 70px;
    font-family:'Bold';
}

.SDTeam .sdBody {
    display:flex;
    flex-direction: column;
    
    
    height:auto;
    width: 65vw;
    background-color: whitesmoke;
    padding-left: 17.5vw;
    padding-right: 17.5vw;
    padding-bottom: 10vh;
    padding-top: 10vh;
    margin-top: 10vh;
    justify-content: left;
    align-items: left;
    text-align: left;
}
.SDTeam .sdBody .sdBodyLeft .raTeamPic img{
    width: 25vw;
}
.SDTeam .sdBody h1{
    justify-self: left;
    align-self: left;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 0px;
    
    font-weight: 50;
    color: #121619;
    font-size: 45px;
    font-family:'Bold';
}

.SDTeam .sdBody h3{
    margin-top: 5vh;
    margin-bottom: 10px;
    justify-self: left;
    align-self: left;
    text-align: left;
    font-weight: 600;
    color: #121619;
    font-size: 35px;
    font-family:'Regular';
}
.SDTeam .sdBody p{
    margin-top: 0px;
    margin-bottom: 10px;
    justify-self: left;
    align-self: left;
    text-align: left;
    font-weight: 500;
    color: #121619;
    font-size: 25px;
    font-family:'Regular';
}
.SDTeam .sdBody .sdBodyLeft {
    align-items:center;
    justify-content: center;
    text-align: center;
    background-color: #121619;
    height:auto;
    width: auto;
}

.SDTeam .sdBody .sdBodyLeft img{
    width: 65vw;
    height: auto;
}

.SDTeam .sdBody .sdBodyRight {
    display:flex;
    flex-direction: column;
    align-items:left;
    justify-content: center;
    margin-top: 20px;
    width:auto;
    height:auto;
    background-color: whitesmoke;
    
}

.SDTeam .sdBody .sdBodyRight h3{
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: lighter;
    font-size: 30px;
    color: #121619;
    font-family:'Regular';  
    text-align: left;
}

.SDTeam .sdBody .sdBodyRight h1 {
    padding-left: 0vw;
    padding-right: 0vw;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 32.5px;
    color: #121619;
    font-family:'Bold';
    text-align: left;  
}

@media only screen and (max-aspect-ratio:1 / 1){
    .SDTeam .sdBody h1{
        justify-self: left;
        align-self: left;
        text-align: left;
        font-weight: 50;
        color: #121619;
        font-size: 30px;
        font-family:'Bold';
    }
    .SDTeam .sdBody h3{
        margin-top: 5vh;
        margin-bottom: 10px;
        justify-self: left;
        align-self: left;
        text-align: left;
        font-weight: 600;
        color: #121619;
        font-size: 21px;
        font-family:'Regular';
    }
    
    .SDTeam .sdBody p{
        margin-top: 0px;
        margin-bottom: 10px;
        justify-self: left;
        align-self: left;
        text-align: left;
        font-weight: 500;
        color: #121619;
        font-size: 15px;
        font-family:'Regular';
    }
    .SDTeam .sdBody .sdBodyRight h3{
        margin-bottom: 0px;
        margin-top: 0px;
        font-weight: lighter;
        font-size: 15px;
        color: #121619;
        font-family:'Regular';  
        text-align: left;
    }
    
}






