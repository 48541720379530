.ContactContainer{
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding-top: 10vh;
    margin-bottom: 20vh;
    height:30vh;
    background-color: #121619;
    
 }
.ContactPage{
    background-color:whitesmoke; 
 }
.ContactContainer h1{
    font-weight: 50;
    color:whitesmoke;
    font-size: 70px;
    font-family:'Regular';
  
}
.ContactContainer h2{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding-left: 20vw;
    line-height: 1px;
    padding-top: 3vh;
    color: #1d2328;
}

.nameInput{
    padding-left: 20vw;
    padding-bottom: 3vh;
} 

.custom-input{
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    width: 50vw;
    /* Additional styles */
    background-color: #f7f7f7;
    color: #121619;     
} 

.emailInput{
    padding-left: 20vw;
    padding-bottom: 3vh;
} 

.subjectInput{
    padding-left: 20vw;
    padding-bottom: 3vh;

} 
.messageInput{
    padding-left: 20vw;
    padding-bottom: 3vh;
}
.buttonContainer{
    padding-top: 3vh;
    padding-left: 20vw;
    padding-bottom: 3vh;
}

.button{
    
    border: 2px solid #121619;
    border-radius: 5px;
    font-size: 16px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    width: 10vw;
    height: 3vw;
    /* Additional styles */
    background-color: #f7f7f7;
    color: #121619; 
    
}

@media only screen and (max-aspect-ratio:1 / 1) {
    .ContactContainer {
        display:flex;
        position: relative;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        padding-top: 10vh;
        margin-bottom: 7.5vw;
        height:22vh;
        background-color: #121619;
    }
    .ContactContainer h1{
        font-weight: 50;
        color:whitesmoke;
        font-size: 50px;
        font-family:'Regular';
      
    }
}