.header {
    display:flex;
    position: relative;
    flex-direction: row;
    height: 90vh;
    width: 100vw;
    padding-top: 10vh; 
    align-items: center;  
    margin-bottom: 20vh;
    background-color: #121619;   
}

.header img{
    width: 23vw;
    height: 35vw;
    filter: contrast(1.08);
    padding-left: 6vw;
    padding-top: 0vh;
    padding-bottom: 0vh;
}

.header .slider img{
    width: 60vw;
    height: 35vw;
    filter: contrast(1.08);
    padding-left: 0vw;
    padding-top: 0vh;
    padding-bottom: 0vh;
}

.body{
    flex-direction: column;
    align-items: center;
    display:flex;
}



.body h1{
    padding-left: 6vw;
    padding-right: 6vw;
    margin-bottom: 10vh;
    margin-top: 0px;
    color: BLACK;
    line-height: 50px;
    font-weight: 300;
    font-size: 55px;
    text-align: center;
    font-family:"Bold";   
}

.body h3{
    margin-bottom: 10vh;
    margin-top: 0px;
    line-height: 50px;
    
    padding-left: 6vw;
    padding-right: 6vw;
    color: BLACK;
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    font-family:"Bold";   
}

.body p{
    padding-left: 6vw;
    padding-right: 6vw;
    color: BLACK;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 30px;
    text-align: center;
    font-family:"Regular";   
}

.ytVid {
    width:864px;
    height:464px; 
    border: 'none';
    margin-bottom: 10vh;
}

.bodyMiddle {
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    height:auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
    width:100vw;
    
    background-size: auto;
    /**background-image: url(../assets/Campus_Aerial_View_2.jpeg);**/
    background-color: whitesmoke;
    /**background-color: #1c3b7b;**/
}

.bodyMiddle h1{
    padding-left: 6vw;
    padding-right: 6vw;
    margin-top: 0px;
    margin-bottom: 0px;
    color: BLACK;
    font-size: 60px;
    line-height: 60px;
    text-align: center;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.teamsContainer {
    display:flex;
    flex-direction: column;
    justify-content: center;
    height:auto;
    width:100vw;
    margin-top: 0px;
    margin-bottom: 15vh;
    padding-top: 0px;
    padding-bottom: 0vh;
    background-size: auto;
    /**background-image: url(../assets/Campus_Aerial_View_2.jpeg);**/
    background-color: whitesmoke;
    /**background-color: #1c3b7b;**/
}

.teamsContainer h1{
    padding-left: 6vw;
    padding-right: 6vw;
    margin-bottom: 5vh;
    margin-top: 0px;
    color: BLACK;
    font-size: 55px;
    line-height: 55px;
    text-align: center;
    font-family:"Bold";
}

.teamsContainer h3{
    padding-left: 6vw;
    padding-right: 6vw;
    line-height: 40px;
    margin-bottom: 5vh;
    margin-top: 0px;
    color: BLACK;
    font-weight: 200;
    font-size: 40px;
    text-align: left;
    font-family:"Bold";    
}
.teamsContainer p{
    padding-left: 6vw;
    padding-right: 6vw;
    margin-top: 0px;
    margin-bottom: 5vh;
    color: BLACK;
    font-weight: 200;
    font-size: 30px;
    text-align: left;
    font-family:"Regular";    
}
/*
@media only screen and (aspect-ratio: 1/1){
    .header{
        height:47vw;
        align-items: center;  
    }
    .header img{
        width: 23vw;
        height: 35vw;
        filter: contrast(1.08);
        padding-left: 6vw;
        padding-top: 0vh;
        padding-bottom: 0vh;
    }
    
    .header .slider img{
        width: 60vw;
        height: 35vw;
        filter: contrast(1.08);
        padding-left: 0vw;
        padding-top: 0vh;
        padding-bottom: 0vh;
    }
}
*/

@media only screen and (max-aspect-ratio:1 / 1){
    .header {
        display:flex;
        position: relative;
        flex-direction: row;
        height: 59vw;
        width: 100vw;
        padding-top: 7.5vh; 
        align-items: center;  
        margin-bottom: 6vw;
        background-color: #121619;   
    }
    .body h1{
        padding-left: 12.5vw;
        padding-right: 12.5vw;
        margin-bottom: 3vw;
        margin-top: 0px;
        color: BLACK;
        line-height: 35px;
        font-weight: 300;
        font-size: 35px;
        text-align: center;
        font-family:"Bold";   
    }
    .body h3{
        margin-bottom: 3vw;
        margin-top: 0px;
        line-height: 20px;
        
        padding-left: 6vw;
        padding-right: 6vw;
        color: BLACK;
        font-weight: 300;
        font-size: 15px;
        text-align: center;
        font-family:"Bold";   
    }
    .body p{
        padding-left: 6vw;
        padding-right: 6vw;
        color: BLACK;
        font-weight: 300;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 15px;
        text-align: center;
        font-family:"Regular";     
    }
    .ytVid {
        width:88vw;
        height:50vw; 
        border: 'none';
        margin-bottom: 3vw;
    }
    .bodyMiddle {  
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        height:auto;
        margin-top: 6vw;
        margin-bottom: 6vw;
        width:88vw;
        background-size: auto;
        /**background-image: url(../assets/Campus_Aerial_View_2.jpeg);**/
        background-color: whitesmoke;
        /**background-color: #1c3b7b;**/
    }
    .bodyMiddle h1{
        padding-left: 6vw;
        padding-right: 6vw;
        margin-top: 0px;
        margin-bottom: 0px;
        color: BLACK;
        font-size: 35px;
        line-height: 35px;
        text-align: center;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
    .teamsContainer {
        display:flex;
        flex-direction: column;
        justify-content: center;
        height:auto;
        width:100vw;
        margin-top: 0px;
        margin-bottom: 6vw;
        padding-top: 0px;
        padding-bottom: 0vh;
        background-size: auto;
        /**background-image: url(../assets/Campus_Aerial_View_2.jpeg);**/
        background-color: whitesmoke;
        /**background-color: #1c3b7b;**/
    }
    .teamsContainer h1{
        padding-left: 17.5vw;
        padding-right: 17.5vw;
        margin-bottom: 6vw;
        margin-top: 0px;
        color: BLACK;
        font-size: 30px;
        line-height: 32px;
        text-align: center;
        font-family:"Bold";
    }
    
    .teamsContainer h3{
        padding-left: 6vw;
        padding-right: 6vw;
        line-height: 20px;
        margin-bottom: 3vw;
        margin-top: 0px;
        color: BLACK;
        font-weight: 200;
        font-size: 20px;
        text-align: left;
        font-family:"Bold";    
    }
    .teamsContainer p{
        padding-left: 6vw;
        padding-right: 6vw;
        margin-top: 0px;
        margin-bottom: 6vw;
        color: BLACK;
        font-weight: 200;
        font-size: 15px;
        text-align: left;
        font-family:"Regular";    
    }
    

}

/*
@media only screen and (max-width: 1600px) {
    .header {
        display:flex;
        position: relative;
        flex-direction: row;
        height: 90vh;
        width: 100vw;
        padding-top: 10vh;   
        margin-bottom: 20vh;
        background-color: #121619;   
    }
    .header img{
        width: 23vw;
        height: 35vw;
        filter: contrast(1.08);
        padding-left: 6vw;
        padding-top: 0vh;
        padding-bottom: 0vh;
    }
    
    .header .slider img{
        width: 60vw;
        height: 35vw;
        filter: contrast(1.08);
        padding-left: 0vw;
        padding-top: 0vh;
        padding-bottom: 0vh;
    }
    .bodyMiddle h1{
        padding-left: 25vw;
        padding-right: 25vw;
        margin-top: 0px;
        margin-bottom: 0px;
        color: BLACK;
        font-size: 60px;
        line-height: 70px;
        text-align: center;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
}
*/
/*
@media only screen and (max-width: 650px) {
    
    .ytVid {
        width:404px;
        height:264px; 
        border: 'none';    
    }

    .header {
        display:flex;
        position: relative;
        flex-direction: row;
        height: 60vw;
        width: 100vw;
        padding-top: 9vw;   
        margin-bottom: 20vw;
        background-color: #121619;   
    }

    .headerContainer img{
        width: 25vw;
        padding-top: 7.5vh;
        padding-left: 0vw;
        padding-right: 0vw;    
    }

    .headerContainer .slider img{
        width: 60vw;
        height: 35vw;
        filter: contrast(1.08);
        padding-left: 0vw;
        padding-top: 0vh;
        padding-bottom: 0vh;
    }

    .body h1{
        padding-left: 12.5vw;
        padding-right: 12.5vw;
        margin-bottom: 10vw;
        margin-top: 0px;
        color: BLACK;
        line-height: 25px;
        font-weight: 300;
        font-size: 27.5px;
        text-align: center;
        font-family:"Bold";   
    }
    .body h3{
        margin-bottom: 10vw;
        margin-top: 0px;
        line-height: 25px;
        padding-left: 10vw;
        padding-right: 10vw;
        color: BLACK;
        font-weight: 300;
        font-size: 15px;
        text-align: center;
        font-family:"Bold";   
    }
    .body p{
        padding-left: 12.5vw;
        padding-right: 12.5vw;
        color: BLACK;
        font-weight: 300;
        font-size: 15px;
        text-align: center;
        font-family:"Regular";   
    }    
    .bodyMiddle {
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        height:auto;
        margin-top: 10vw;
        margin-bottom: 10vw;
        width:100vw;
        background-size: auto;
        background-color: whitesmoke;
        
    }

    .bodyMiddle h1{
        padding-left: 20vw;
        padding-right: 20vw;
        margin-top: 0px;
        margin-bottom: 0px;
        color: BLACK;
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
    
    .teamsContainer {
        display:flex;
        flex-direction: column;
        justify-content: center;
        height:auto;
        width:100vw;
        margin-top: 0px;
        margin-bottom: 15vw;
        padding-top: 0px;
        padding-bottom: 0vh;
        background-size: auto;
        background-color: whitesmoke;
    }  
    
    .teamsContainer h3{
        padding-left: 12.5vw;
        padding-right: 12.5vw;
        margin-top: 0px;
        margin-bottom: 5vw;
        color: BLACK;
        font-weight: 200;
        font-size: 25px;
        text-align: left;
        font-family:"Bold";    
    }
    .teamsContainer p{
        padding-left: 12.5vw;
        padding-right: 12.5vw;
        margin-top: 0px;
        margin-bottom:5vw;
        color: BLACK;
        font-weight: 200;
        font-size: 15px;
        text-align: left;
        font-family:"Regular";    
    }
    .teamsContainer h1{
        padding-left: 17.5vw;
        padding-right: 17.5vw;
        margin-bottom: 10vw;
        margin-top: 0px;
        color: BLACK;
        font-size: 27.5px;
        line-height: 25px;
        text-align: center;
        font-family:"Bold";
    }
}
*/

.image-container {
    width: 25vw; 
    height: auto; 
    display: flex;
    justify-content:left;
    align-items: center;
    overflow: hidden; 
}


.flex-image {
    width: 100%; /* Makes the image take up the full width of its container */
    height: auto; /* Allows the image to maintain its aspect ratio */
    object-fit: cover; /* Ensures the image covers the entire container */
}
  