.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  
}
@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("Lato-Regular"),
    url("./fonts/Lato-Font/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("Lato-Bold"),
    url("./fonts/Lato-Font/Lato-Bold.ttf") format("truetype");
}

body{
  padding: 0;
  margin: 0;
}
