/* MENU ITEM STYLING */
.TeamMember{
    display: flex;
    flex-direction: column;
    margin-bottom: 12%;
    
    width: 20vw;
    height: auto;
    position: relative;
    display: inline-block;
}

.TeamMember img{
    width: 20vw;
    overflow: hidden;
    height: auto;
    display: grid;
    place-items: center;
    font-size: small;
}

.TeamMember img:hover{
    opacity: 0.7;
    /* transform: scale(1.025); */
    filter:brightness(125%)
    

}
.TeamMember .MemberDescriptor{
    
    top: 90%; /* Adjust top, left, width, height as needed */
    left: 0%;
    width: 20vw;
    height: auto;
    color: black;
    line-height: 1px;
    border-top: none;  
    padding-bottom: 0.5%;
}

.TeamMember .MemberDescriptor h3{
    font-size: 1.5vw;
    line-height: 1.5vw;
    margin-top: 0.25vw;
    margin-bottom: 0px;
    font-family:'Regular';  
}

.TeamMember .MemberDescriptor p{
    font-size: 1vw;
    line-height: 1vw;
    margin-top: 0.20vw;
    margin-bottom: 0.25px;    
    font-family:'Regular';
}
@media only screen and (max-aspect-ratio:1/1){
    .TeamMember{
        display: flex;
        flex-direction: column;
        margin-bottom: 12%;
        width: 41vw;
        height: auto;
        position: relative;
        display: inline-block;
    }
    .TeamMember img{
        width: 41vw;
        overflow: hidden;
        height: auto;
        display: grid;
        place-items: center;
        font-size: small;
    }
    .TeamMember .MemberDescriptor{
    
        top: 90%; /* Adjust top, left, width, height as needed */
        left: 0%;
        width: 41vw;
        height: auto;
        color: black;
        line-height: 1px;
        border-top: none;  
        padding-bottom: 0.5%;
    }
    
    .TeamMember .MemberDescriptor h3{
        font-size: 3.5vw;
        line-height: 3.5vw;
        margin-top: 0.25vw;
        margin-bottom: 0px;
        font-family:'Regular';  
    }
    
    .TeamMember .MemberDescriptor p{
        font-size: 2.5vw;
        line-height: 2.5vw;
        margin-top: 0.20vw;
        margin-bottom: 0.25px;    
        font-family:'Regular';
    }

}
/*
@media only screen and (max-width: 1300px) {
    
  }
    
  @media only screen and (max-width: 800px) {
    .TeamList {
      grid-template-columns: 1fr 1fr;
    }
    .TeamMember img{
      width: 43vw;
      overflow: hidden;
      height: auto;
      display: grid;
      place-items: center;
      font-size: small;
    }
    .TeamMember .MemberDescriptor{
        position: absolute;
        top: 90%; 
        left: 0%;
        width: 43vw;
        height: 4.2vw;
        background-color: whitesmoke; 
        color: #121619;
        line-height: 1px;
        border-top: none;  
        padding-bottom: 0.5%;
    }
    .TeamMember .MemberDescriptor h3{
        font-size: 3.07vw;
        
        font-family:'Regular';  
    }
    
    .TeamMember .MemberDescriptor p{
        font-size: 2vw;
        
        font-family:'Regular';
    }
  }
  */