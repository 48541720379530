.Team{
  background-color: 	whitesmoke;
  margin-bottom: 7.5vw;  
}

.Team h1{
  margin-left: 7.5vw;
  margin-right: 7.5vw;
  font-weight: 50;
  color:whitesmoke;
  font-size: 70px;
  font-family:'Regular';
}

.TeamHeader {
  display:flex;
  position: relative;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  padding-top: 10vh;
  margin-bottom: 7.5vw;
  height: 30vh;
  width: 100vw;
  /**background-image: url(../assets/Campus_Aerial_View_2_blurry.jpeg);**/
  background-color: #121619;
  /**border-bottom: 3px solid whitesmoke;**/
}
  
.TeamList {
  border: 3px;  
  margin-left: 7.5vw;
  padding-bottom: 5vw;
  width: 85vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
  font-size: large;

  
}
@media only screen and (max-aspect-ratio:1 /1){
  .TeamHeader {
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin-top: 7.5vh;
    margin-bottom: 7.5vw;
    height: 22.5vh;
    width: 100vw;
    /**background-image: url(../assets/Campus_Aerial_View_2_blurry.jpeg);**/
    background-color: #121619;
    /**border-bottom: 3px solid whitesmoke;**/
  } 
  .Team h1{
    margin-left: 7.5vw;
    margin-right: 7.5vw;
    font-weight: 50;
    color:whitesmoke;
    font-size: 30px;
    font-family:'Regular';
  } 
  .TeamList {
    border: 3px;  
    margin-left: 6vw;
    padding-bottom: 5vw;
    width: 88vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    font-size: large;
  
    
  }

}
/*
@media only screen and (max-width: 1400px) {
  .Team h1{
    padding-top: 0vh;
    text-align: left;
    margin-left: 5vw;
    padding-right: 30vw;
    font-weight: 50;
    padding-bottom: 0vh;
    color:whitesmoke;
    font-size: 40px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
}
@media only screen and (max-width: 1300px) {
  .Team h1{
    padding-top: 0vh;
    text-align: left;
    margin-left: 5vw;
    padding-right: 25vw;
    font-weight: 50;
    padding-bottom: 0vh;
    color:whitesmoke;
    font-size: 40px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
}
@media only screen and (max-width: 1200px) {
  .Team h1{
    padding-top: 0vh;
    text-align: left;
    margin-left: 5vw;
    padding-right: 18vw;
    font-weight: 50;
    padding-bottom: 0vh;
    color:whitesmoke;
    font-size: 40px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
}
@media only screen and (max-width: 1200px) {
  .Team h1{
    padding-top: 0vh;
    text-align: left;
    margin-left: 5vw;
    padding-right: 20vw;
    font-weight: 50;
    padding-bottom: 0vh;
    color:whitesmoke;
    font-size: 38px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
}
@media only screen and (max-width: 1100px) {
  .Team h1{
    padding-top: 0vh;
    text-align: left;
    margin-left: 5vw;
    padding-right: 20vw;
    font-weight: 50;
    padding-bottom: 0vh;
    color:whitesmoke;
    font-size: 36px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
}
  
@media only screen and (max-width: 800px) {
  .Team h1{
    padding-top: 0vh;
    text-align: left;
    margin-left: 5vw;
    padding-right: 8vw;
    font-weight: 50;
    padding-bottom: 0vh;
    color:whitesmoke;
    font-size: 34px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  .TeamList {
    grid-template-columns: 1fr 1fr;
  }
 
}
@media only screen and (max-width: 600px) {
  .Team h1{
    padding-top: 0vh;
    text-align: left;
    margin-left: 5vw;
    padding-right: 8vw;
    font-weight: 50;
    padding-bottom: 0vh;
    color:whitesmoke;
    font-size: 30px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  .TeamList {
    grid-template-columns: 1fr 1fr;
  }
 
}
@media only screen and (max-width: 600px) and (max-height:800px) {
  .Team h1{
    padding-top: 0vh;
    text-align: left;
    margin-left: 5vw;
    padding-right: 8vw;
    font-weight: 50;
    padding-bottom: 0vh;
    color:whitesmoke;
    font-size: 25px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  .TeamList {
    grid-template-columns: 1fr 1fr;
  }
 
}
*/